import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import FadeUpIntoView from '../components/FadeUpIntoView';
import Layout from '../components/Layout';
import ProjectGrid from '../components/ProjectGrid';
import SEO from '../components/SEO';

const Work = () => {
  const data = useStaticQuery(graphql`
    query WorkPageQuery {
      content: contentfulWorkPage {
        metaTitle
        metaDescription
        heroTitle
        heroParagraph {
          heroParagraph
        }
        projects {
          slug
          title
          formattedTitle
          textColour: gridTheme
          client
          category
          image: gridImage {
            fluid(maxWidth: 1440, quality: 95) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
          imageHover: gridImageHover {
            fluid(maxWidth: 1440, quality: 95) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
          horizontalImage: horizontalGridImage {
            fluid(maxWidth: 2880, quality: 95) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
          horizontalImageHover: horizontalGridImageHover {
            fluid(maxWidth: 2880, quality: 95) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
        footerPromo
        footerPromoTheme
      }
    }
  `);

  const {
    content: {
      metaTitle,
      metaDescription,
      heroTitle,
      heroParagraph: { heroParagraph },
      footerPromo,
      footerPromoTheme,
      projects,
    },
  } = data;

  return (
    <Layout
      headerTheme="color"
      footerPromo={footerPromo}
      footerPromoTheme={footerPromoTheme}
    >
      <SEO title={metaTitle} description={metaDescription} />
      <section className="panel lead-panel">
        <div className="lead-panel__inner">
          <FadeUpIntoView>
            <h1 className="lead-panel__title">{heroTitle}</h1>
          </FadeUpIntoView>
          <FadeUpIntoView delayBy={2}>
            <p className="lead-panel__subtitle">{heroParagraph}</p>
          </FadeUpIntoView>
        </div>
      </section>
      <ProjectGrid projects={projects} horizontal={true} />
    </Layout>
  );
};

export default Work;
